import store from 'store';
import { axiosErrorHandler, callTheUpServerGet } from './axiosHelper';
import { convertToInt, insertDataToElementUsingClassName, unixTimeStampInSeconds } from './utility';







export const setYamashaUserBasicData = ({ fullName }) => {
    store.set('yamashaUserBasicData', {
        fullName,

        updatedAt: unixTimeStampInSeconds(),
    });
};

export const getYamashaUserBasicData = () => {
  return  new Promise((resolve, reject) => {
        (async () => {
            try {
                let yamashaUserBasicData = store.get('yamashaUserBasicData');

                if (!yamashaUserBasicData) {
                    yamashaUserBasicData = {


                        fullName: undefined,

                        updatedAt: undefined,
                    };
                }else{
                    yamashaUserBasicData = {
                        fullName: yamashaUserBasicData.fullName,
    
                        updatedAt: convertToInt(yamashaUserBasicData.updatedAt),
                    };
                }
                   resolve(yamashaUserBasicData);
             
            } catch (error) {
                console.log(error);
                reject(error);
            }
        })();
    });

};

export const refreshApBasicData = () => {
    return new Promise((resolve, reject) => {
        try {
            callTheUpServerGet('/basic').then((response) => {
                const res = response.data;
                // console.log(response);

                const { fullName, appName, appUsername, activePlan, depositCredit, bonusCredit, logoLink } = res;
                setYamashaUserBasicData({ fullName, appName, appUsername, activePlan, depositCredit, bonusCredit, logoLink });

                resolve(true);

            }).catch((error) => {
                axiosErrorHandler(error);
                console.log(error);

            });
        } catch (error) {
            console.log(error);
            reject(error);
        }
    });

};

export const hydrateUserBasicData = () => {

    return new Promise((resolve, reject) => {

        (async () => {
            try {

                const yamashaUserBasicData = await getYamashaUserBasicData();
              



                insertDataToElementUsingClassName('full-name-text-here', yamashaUserBasicData.fullName);



               
                resolve(true);
            } catch (error) {
                console.log(error);
                reject(error);
            }
        })();
    });



};