

// import Swal from 'sweetalert2';
// import { DataTable } from "simple-datatables";
// import moment from 'moment';
import ClipboardJS from 'clipboard';
import moment from 'moment';
import authHelper from './helpers/authHelper.js';
import { axiosErrorHandler, axiosResponseHandler, callTheUpServerGet } from './helpers/axiosHelper.js';
import { hydrateUserBasicData } from './helpers/basicHelper.js';
import { byId, unixTimeStampInSeconds } from './helpers/utility.js';

authHelper.validate().then(async () => {

    await hydrateUserBasicData();



    //updating wallets
    const referral_points = byId('referral_points');
    const t_body = byId('t_body');
    const refer_link = byId('refer_link');


    const url = new URL('./register.html', location.href);
    // if (location.host == '192.168.100.5') {
    //     url = 'http://192.168.100.5/yamasha_v1/user_panel/register.html';
    // } else {
    //     url = 'https://user.yamasha.in/register.html';
    // }
   

    new ClipboardJS('#copy_btn');

    const showReferrals = () => {

        const showReferralsList = document.getElementById('refList');
        const viewRef = document.getElementById('referrals');

        showReferralsList.style.display = 'block';
        viewRef.style.display = 'none';

    };
    window.showReferrals = showReferrals;

    const show = () => {
        const hide = document.getElementById('hide');
        const d_show = document.getElementById('show');
        d_show.style.display = 'block';
        hide.style.display = 'none';
    };
    window.show = show;


    const referAndEarnPageFun = {
        fetch: {
            walletData: async () => {
                try {

                    const response = await callTheUpServerGet('/wallet/getWalletData');
                    axiosResponseHandler(response);
                    const res = response.data;
                    if (res.status === 'success') {
                        console.log(res);
                        const { walletData } = res;

                        referral_points.innerHTML = walletData.referralReward;
                    }


                } catch (error) {
                    axiosErrorHandler(error);
                }
            },
            referralCode: async () => {
                try {

                    const response = await callTheUpServerGet('/basic/getReferralCode');
                    axiosResponseHandler(response);
                    const res = response.data;
                    if (res.status === 'success') {
                        console.log(res);
                        const {referralCode} =res;

                         refer_link.value = url + `?refer=${referralCode}`;

                    }


                } catch (error) {
                    axiosErrorHandler(error);
                }
            },
            referralsHistory: async () => {
                try {

                    const response = await callTheUpServerGet('/basic/getReferralsHistory');
                    axiosResponseHandler(response);
                    const res = response.data;
                    if (res.status === 'success') {
                        console.log(res);
                        const { referralsHistory } = res;
                        if (referralsHistory.length > 0) {

                            for (let i = 0; i < referralsHistory.length; i++) {
                                const { all_users_all_usersTousers_referral_details_userId, isReferredByRewarded, referredByRewardDate, createdAt } = referralsHistory[i];
                                const { fullName } = all_users_all_usersTousers_referral_details_userId;
                                const referralDate = moment(createdAt * 1000).format('DD/MM/YYYY');
                            let     rewardDate;
                                if(referredByRewardDate){
                                     rewardDate = moment(referredByRewardDate * 1000).format('DD/MM/YYYY');
                                }

                                let referredByRewardedStatus = ``;
                                if (isReferredByRewarded) {
                                    referredByRewardedStatus = 'Success';
                                } else {
                                    const diff = unixTimeStampInSeconds() - createdAt;

                                    if (diff < (60 * 60 * 24 * 30)) {
                                        referredByRewardedStatus = 'Pending';
                                    } else {
                                        referredByRewardedStatus = 'Expired';
                                    }
                                }


                                t_body.innerHTML += `<tr class="table-line ">
                                    <td>
                                        <p class="font-weight-bold">${referralDate}</p>
                                    </td>
                                    <td>
                                        <p class="text-capitalize">${fullName}</p>

                                    </td>
                                    <td class="">
                                        <p>${referredByRewardedStatus}</p>
                                    </td>
                                    <td>
                                        <p class="font-weight-bold">${rewardDate}</p>
                                    </td>
                                </tr>`;

                            }
                        }
                    }


                } catch (error) {
                    axiosErrorHandler(error);
                }
            }
        }
    };

    window.referAndEarnPageFun = referAndEarnPageFun;
    referAndEarnPageFun.fetch.walletData();
    referAndEarnPageFun.fetch.referralCode();
    referAndEarnPageFun.fetch.referralsHistory();

  

});











